<template>
  <div class="ecology">
    <div class="ecology-search-box">
      <div class="ecology-search" id="search">
        <input class="ecology-search-input font-condensed" type="text" v-model="search" placeholder="Search" />
      </div>
    </div>
    <div class="ecology-content">
      <ul class="ecology-chain">
        <li
          class="ecology-chain-bg"
          :style="{ transform: `translateY(${chainActiveIndex * (48 + 24)}px)` }"
          key="bg"
        ></li>
        <!--          :class="{ show: chainListBgShow }"-->
        <li
          class="ecology-chain-item font-bold"
          v-for="(item, key) in chainList"
          :key="item"
          :class="{ active: item === chainActive, all: item === 'All' }"
          v-bind:data-index="key"
          @click="changeChain(item)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="ecology-system">
        <transition-group
          class="ecology-system-list"
          name="system"
          tag="div"
          @enter="systemEnter"
          @before-enter="systemBeforeEnter"
          @before-leave="systemBeforeLeave"
          @leave="systemLeave"
        >
          <a
            class="ecology-system-item"
            :href="item.url"
            target="_blank"
            rel="noopener noreferrer"
            v-for="(item, key) in systemPageListSet"
            :key="item.name"
            :data-index="key"
          >
            <div class="ecology-system-icon">
              <img :src="require(`@/assets/images/ecosystem/${item.logo}`)" alt="" />
            </div>
            <div class="ecology-system-note font-condensed">{{ item.chain }}</div>
            <div class="ecology-system-title font-bold">{{ item.name }}</div>
          </a>
        </transition-group>
        <div class="ecology-system-none" :class="{ show: systemPageList.length === 0 && search !== '' }">
          <h5 class="ecology-system-none-title font-bold">No Results Found</h5>
          <div class="ecology-system-none-note font-condensed">Try specifying a more complete search term</div>
        </div>
        <div class="ecology-paging" v-show="maxPageNumber > 1">
          <div class="ecology-paging-btn prev" :class="{ disable: page === 0 }" @click="prev">
            <i class="common-icon-List-prev"></i>
          </div>
          <div class="ecology-paging-btn next" :class="{ disable: page === maxPageNumber - 1 }" @click="next">
            <i class="common-icon-List-next"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { gsap, ScrollTrigger } from '@/tools/gsap'
const MAXPAGE = 24
const ScrollTriggerList = []

export default {
  name: 'Ecology',
  data() {
    return {
      chainListBgShow: false,
      search: '',
      chainActive: 'All',
      page: 0,
      setZero: false,
      first: true
    }
  },
  computed: {
    ...mapState(['ecosystemData']),
    chainList() {
      const noBlockchain = Object.keys(this.ecosystemData)
        .filter(item => {
          // return item !== 'Blockchains'
          return item
        })
        .sort((a, b) => a.localeCompare(b))
      return ['All', ...noBlockchain]
    },
    chainActiveIndex() {
      return this.chainList.indexOf(this.chainActive)
    },
    systemList() {
      let list = []
      if (this.chainActive === 'All') {
        Object.entries(this.ecosystemData).forEach(([key, item]) => {
          list.push(
            ...item.map(child => {
              return { ...child, chain: key }
            })
          )
        })
      } else {
        list = this.ecosystemData[this.chainActive].map(item => {
          return { ...item, chain: this.chainActive }
        })
      }
      if (!this.chainListBgShow) {
        return list.slice(0, 3)
      }
      if (this.search === '') {
        return list
      }
      return list.filter(item => {
        const regex = new RegExp(this.search, 'i')
        return regex.test(item.name)
      })
    },
    systemPageList() {
      if (this.systemList.length > MAXPAGE) {
        return this.systemList.slice(this.page * MAXPAGE, (this.page + 1) * MAXPAGE)
      }
      return this.systemList
    },
    systemPageListSet() {
      if (this.setZero) {
        return []
      }
      return this.systemPageList
    },
    maxPageNumber() {
      return Math.ceil(this.systemList.length / MAXPAGE)
    }
  },
  watch: {
    systemPageList() {
      if (this.first) {
        this.first = false
        return
      }
      this.setZero = true
      this.$nextTick(() => {
        this.setZero = false
      })
    }
  },
  methods: {
    changeChain(key) {
      this.chainActive = key
      this.page = 0
    },
    next() {
      if (this.page < this.maxPageNumber - 1) {
        this.page += 1
        this.scrollToSearch()
      }
    },
    prev() {
      if (this.page > 0) {
        this.page -= 1
        this.scrollToSearch()
      }
    },
    scrollToSearch() {
      gsap.to(window, { duration: 0.3, scrollTo: { y: '#search', offsetY: 100 } })
    },
    systemBeforeEnter(el) {
      el.style.opacity = 0
    },
    systemBeforeLeave(el) {
      el.style.position = 'absolute'
      el.style.left = '-1000px'
    },
    systemEnter(el, done) {
      const delay = el.dataset.index * 50
      setTimeout(() => {
        gsap.fromTo(
          el,
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            onComplete: done
          }
        )
      }, delay)
    },
    systemLeave(el) {
      el.style.opacity = 0
      // setTimeout(() => {
      //   gsap.to(el, {
      //     opacity: 0,
      //     y: -10,
      //     onComplete: done
      //   })
      // }, 0)
    }
  },
  mounted() {
    const chainItemList = document.querySelectorAll('.ecology-chain-item')
    const chainItemAnime = gsap.to(chainItemList, {
      opacity: 1,
      stagger: 0.1,
      y: 0,
      paused: true,
      onStart: () => {
        this.chainListBgShow = true
      }
    })
    document.fonts.ready.then(() => {
      ScrollTriggerList.push(
        ScrollTrigger.create({
          trigger: '.ecology-content',
          start: 'top center',
          once: true,
          markers: process.env.NODE_ENV === 'development',
          onEnter: () => {
            chainItemAnime.play()
          }
        })
      )
    })
  },
  beforeDestroy() {
    ScrollTriggerList.forEach(item => {
      item.kill()
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/font.scss';
@import '~@/assets/style/mixin.scss';
.ecology {
  width: var(--content-width);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
  .ecology-search-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .ecology-search {
    display: block;
    position: relative;
    margin-left: auto;
    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      left: 8px;
      top: 12px;
      z-index: 1;
      background-image: url('~@/assets/images/icon/searchB.svg');
      background-size: 100%;
      opacity: 0.5;
      transition: opacity 0.3s;
    }
    &:has(.ecology-search-input:focus)::before {
      opacity: 1;
    }
  }
  .ecology-search-input {
    background-color: #fff;
    border-radius: 24px;
    border: 2px solid transparent;
    outline: none;
    padding: 12px 16px 12px 40px;
    height: 48px;
    line-height: 1em;
    transition: border-color 0.3s;
    &:focus {
      border-color: var(--main);
    }
    &::placeholder {
      font-size: 14px;
      color: var(--font-sub);
      line-height: 24px;
      font-weight: 400;
      @include font-select('cmr_regular');
    }
  }
  .ecology-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
  .ecology-chain {
    position: relative;
    z-index: 1;
  }
  .ecology-chain-bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 48px;
    background-color: #fff;
    box-shadow: 0px 8px 20px 0px rgba(8, 10, 50, 0.06);
    border-radius: 24px;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 1;
    //&.show {
    //  opacity: 1;
    //}
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border-radius: 48px;
      border: 1px solid rgba(5, 19, 46, 0.08);
      transform-origin: left top;
      transform: scale(0.5);
    }
  }
  .ecology-chain-item {
    min-width: 176px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--font-note);
    cursor: pointer;
    transition: color 0.3s;
    &:not(.all) {
      opacity: 0;
      transform: translateY(10px);
    }
    &:nth-of-type(n + 3) {
      margin-top: 24px;
    }
    &:hover {
      color: var(--font-sub);
    }
    &.active {
      color: var(--main);
    }
  }
  .ecology-system {
    width: 888px;
    position: relative;
  }
  .ecology-system-list {
    display: grid;
    grid-template-columns: 280px 280px 280px;
    grid-row-gap: 32px;
    grid-column-gap: 24px;
    //width: 100%;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: flex-start;
    //align-items: flex-start;
  }
  .ecology-system-item {
    display: block;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    transition: box-shadow 0.3s;
    height: 182px;
    //transition: all 3s;
    //width: 280px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border-radius: 40px;
      border: 1px solid rgba(5, 19, 46, 0.08);
      transform-origin: left top;
      transform: scale(0.5);
    }
    &:hover {
      box-shadow: 0px 12px 24px 0px rgba(8, 10, 50, 0.06);
    }
  }
  .ecology-system-icon {
    width: 240px;
    height: 72px;
  }
  .ecology-system-note {
    margin-top: 16px;
    font-size: 14px;
    color: var(--font-sub);
    line-height: 20px;
    font-weight: 400;
  }
  .ecology-system-title {
    margin-top: 2px;
    font-size: 20px;
    color: var(--main);
    line-height: 24px;
    font-weight: 500;
  }
  .ecology-paging {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 56px;
  }
  .ecology-paging-btn {
    width: 56px;
    height: 56px;
    border-radius: 56px;
    background-color: #fff;
    padding: 16px;
    @include halfBorder(before, rgba(5, 19, 46, 0.08));
    transition: box-shadow 0.3s;
    cursor: pointer;
    &:hover:not(.disable) {
      box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
    }
    &.disable {
      background-color: #fafafa;
      cursor: not-allowed;
      & > i {
        opacity: 0.2;
      }
    }
    &.prev {
    }
    &.next {
      margin-left: 24px;
    }
  }
  .ecology-system-none {
    text-align: center;
    padding-top: 200px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    position: absolute;
    width: 100%;
    &.show {
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);
      transition: opacity 0.3s, transform 0.3s;
    }
  }
  .ecology-system-none-title {
  }
  .ecology-system-none-note {
    margin-top: 4px;
    font-size: 14px;
    color: var(--font-sub);
    line-height: 24px;
    font-weight: 400;
  }
}
</style>
