<template>
  <div class="discover">
    <div class="discover-content common-content">
      <div class="common-transition-box">
        <transition name="transition-title">
          <h1 class="discover-title" v-show="show">
            Function X Ecosystem
          </h1>
        </transition>
      </div>
      <transition name="transition-text">
        <div class="discover-info common-text" v-show="show">
          Discover all the dapps and integrations on f(x)Core. <br />Are you developing something new?
          <a
            href="https://functionx.gitbook.io/functionx-egf-proposal-guidelines/"
            target="_blank"
            rel="noopener noreferrer"
            class="discover-link"
            >Apply for our Ecosystem Genesis Fund</a
          >
        </div>
      </transition>
    </div>
    <div class="discover-banner">
      <div class="discover-point-big"></div>
      <div class="discover-point-small"></div>
      <div class="discover-outline">
        <img src="@/assets/images/ecosystem/ecosystem_01_outline.svg" alt="" />
      </div>
      <div class="discover-ball-left">
        <img src="@/assets/images/ecosystem/ecosystem_01_leftball.svg" alt="" />
      </div>
      <div class="discover-ball-right">
        <img src="@/assets/images/ecosystem/ecosystem_01_rightball.svg" alt="" />
      </div>
      <div class="discover-build">
        <img src="@/assets/images/ecosystem/ecosystem_o1_main.png" alt="" />
      </div>
      <div class="discover-base-small">
        <img src="@/assets/images/ecosystem/ecosystem_01_avalanche2.png" alt="" />
        <div class="discover-avalanche">
          <img src="@/assets/images/ecosystem/ecosystem_01_avalanche1.png" alt="" />
        </div>
      </div>
      <div class="discover-base-big">
        <img src="@/assets/images/ecosystem/ecosystem_01_solana2.png" alt="" />
        <div class="discover-solana">
          <img src="@/assets/images/ecosystem/ecosystem_01_solana1.png" alt="" />
        </div>
      </div>
      <div class="discover-eth">
        <img src="@/assets/images/ecosystem/ecosystem_01_eth.png" alt="" />
      </div>
      <div class="discover-fx">
        <img src="@/assets/images/ecosystem/ecosystem_01_fx.png" alt="" />
      </div>
      <div class="discover-bitcoin">
        <img src="@/assets/images/ecosystem/ecosystem_01_bitcoin.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsapDestroy } from '@/tools/mixin'
import { animeTitleShow } from '@/tools/utils'

export default {
  name: 'Discover',
  data() {
    return {
      show: false
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.show = true
      this.$store.commit('setBridgeResourceReady', true)
    })
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      },
      move: 'left'
    })
  }
}
</script>

<style scoped lang="scss">
@mixin createUpAndDown($name, $translateX, $y: -10px) {
  @keyframes #{$name} {
    from {
      transform: translate(#{$translateX}, 0);
    }
    to {
      transform: translate(#{$translateX}, $y);
    }
  }
}
.discover {
  width: 100%;
  min-height: 960px;
  padding-top: 88px;
  padding-bottom: 152px;
  background-image: linear-gradient(180deg, #ffffff 0%, #eae4ff 69%, #ffffff 99%);
  overflow: hidden;
  .discover-content {
    text-align: center;
    min-height: 149px;
  }
  .discover-title {
    white-space: nowrap;
  }
  .discover-info {
  }
  .discover-link {
    text-decoration: underline;
  }
  .discover-banner {
    width: 1242px;
    min-height: 447px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 125px;
    & > div:not(.discover-build) {
      position: absolute;
    }
  }
  .discover-point-big {
    width: 322px;
    height: 322px;
    background-color: #fff6c1;
    border-radius: 500px;
    filter: blur(20px);
    bottom: 71px;
    left: 50%;
    transform: translateX(-219px);
  }
  .discover-point-small {
    width: 256px;
    height: 256px;
    background-color: #e8d3ff;
    border-radius: 500px;
    filter: blur(20px);
    bottom: 156px;
    left: 50%;
    transform: translateX(-91px);
  }
  .discover-ball-left {
    right: 50%;
    top: 54px;
    transform: translateX(-138px);
  }
  .discover-ball-right {
    left: 50%;
    top: 44px;
    transform: translateX(129px);
  }
  .discover-outline {
    width: 1736px;
    left: 50%;
    bottom: -48px;
    transform: translateX(-50%);
  }
  .discover-build {
    position: relative;
  }
  .discover-base-small {
    $name: upAndDown-small;
    $left: 452px;
    top: -110px;
    width: 86px;
    left: 50%;
    transform: translateX($left);
    @include createUpAndDown($name, $left);
    animation: $name 3s 1.5s ease-in-out infinite alternate;
  }
  .discover-avalanche {
    $name: upAndDown-small-icon;
    position: absolute;
    width: 38px;
    top: -21px;
    left: 23px;
    @include createUpAndDown($name, 0, -6px);
    animation: $name 3s 1s ease-in-out infinite alternate;
  }
  .discover-base-big {
    $name: upAndDown-big;
    $left: -429px;
    width: 152px;
    top: -12px;
    right: 50%;
    transform: translateX($left);
    @include createUpAndDown($name, $left);
    animation: $name 3s 2s ease-in-out infinite alternate;
  }
  .discover-solana {
    $name: upAndDown-big-icon;
    $left: -50%;
    position: absolute;
    width: 67px;
    top: -28px;
    left: 50%;
    transform: translateX($left);
    @include createUpAndDown($name, $left);
    animation: $name 3s 2s ease-in-out infinite alternate;
  }
  .discover-eth {
    $name: upAndDown-eth;
    $left: -2px;
    width: 64px;
    top: -71px;
    left: 50%;
    transform: translateX($left);
    @include createUpAndDown($name, $left);
    animation: $name 3s ease-in-out infinite alternate;
  }
  .discover-fx {
    $name: upAndDown-fx;
    $left: -140px;
    width: 79px;
    left: 50%;
    top: 25px;
    transform: translateX(-140px);
    @include createUpAndDown($name, $left);
    animation: $name 3s 0.5s ease-in-out infinite alternate;
  }
  .discover-bitcoin {
    $name: upAndDown-bitcoin;
    $left: 16px;
    width: 85px;
    left: 50%;
    top: 168px;
    transform: translateX(16px);
    @include createUpAndDown($name, $left);
    animation: $name 3s 1s ease-in-out infinite alternate;
  }
}
</style>
