<template>
  <Default>
    <div class="ecosystems">
      <Discover></Discover>
      <Ecology></Ecology>
    </div>
  </Default>
</template>

<script>
import Default from '@/components/layout/Default.vue'
import Discover from '@/components/ecosystem/Discover.vue'
import Ecology from '@/components/ecosystem/Ecology.vue'

export default {
  name: 'Ecosystems',
  components: { Ecology, Discover, Default }
}
</script>

<style scoped></style>
